@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;600;700&display=swap');

* {
  scroll-behavior: smooth;
  box-sizing: border-box;
  margin: 0;
  font-family: 'Lexend', sans-serif;
}

#main-app-layout *:not(.user-profile *) {
  font-family: 'Overpass', sans-serif;
}

@font-face {
  font-family: 'TS Block';
  font-weight: 700;
  src: local('TS-Block-Bold'), url(../public/fonts/TS-Block-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'DarkerGrotesque';
  font-weight: 400;
  src: local('DarkerGrotesque-Regular'), url(../public/fonts/DarkerGrotesque-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Himagsikan';
  src: local('Himagsikan'), url(../public/fonts/Himagsikan.ttf) format('truetype');
}

@font-face {
  font-family: 'Conthrax';
  font-weight: 400;
  src: local('ConthraxSb-Regular'), url(../public/fonts/ConthraxSb-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Overpass';
  font-weight: 400;
  src: local('Overpass-Regular'), url(../public/fonts/Overpass-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Overpass';
  font-weight: 500;
  src: local('Overpass-Medium'), url(../public/fonts/Overpass-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Overpass';
  font-weight: 700;
  src: local('Overpass-Bold'), url(../public/fonts/Overpass-Bold.ttf) format('truetype');
}
